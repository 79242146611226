@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.SuperHubSearch {
  --SuperHubSearch-background: var(--white);
  --SuperHubSearch-color: var(--gray20);
  --SuperHubSearch-color-excerpt: var(--gray30);
  --SuperHubSearch-empty-color: var(--gray30, #{get(color 'graphite')});
  --SuperHubSearch-pagination-border: #eee;
  --SuperHubSearch-pagination-color: var(--gray40);
  --SuperHubSearch-result-background: none;
  --SuperHubSearch-result-background-hover: #{rgba(black, 0.05)};
  --SuperHubSearch-result-background-active: var(--project-color-primary, #{get(color 'blue')});
  --SuperHubSearch-result-color-active: var(--project-color-inverse, #fff);
  --SuperHubSearch-result-icon-opacity: 0.75;
  --SuperHubSearch-tab-color: #eee;
  --SuperHubSearch-tab-icon-opacity: 0.75;
  --SuperHubSearch-max-width: 900px;

  @include dark-mode {
    --SuperHubSearch-background: var(--gray20);
    --SuperHubSearch-color: var(--white);
    --SuperHubSearch-color-excerpt: var(--gray80);
    --SuperHubSearch-empty-color: var(--gray80);
    --SuperHubSearch-pagination-border: #{rgba(white, 0.1)};
    --SuperHubSearch-pagination-color: var(--gray80);
    --SuperHubSearch-result-background: none;
    --SuperHubSearch-result-background-hover: #{rgba(white, 0.05)};
    --SuperHubSearch-result-icon-opacity: 0.5;
    --SuperHubSearch-tab-color: var(--gray20);
    --SuperHubSearch-tab-icon-opacity: 0.5;
  }

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    --SuperHubSearch-background: 255, 255, 255;

    [data-color-mode='dark'] & {
      --SuperHubSearch-background: 56, 66, 72; // slate as rgb
    }

    [data-color-mode='system'] & {
      @media (prefers-color-scheme: dark) {
        --SuperHubSearch-background: 56, 66, 72; // slate as rgb
      }
    }
  }

  background-color: var(--SuperHubSearch-background);
  border-radius: var(--border-radius-lg, 7.5px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 2px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  color: var(--SuperHubSearch-color);
  display: flex;
  height: 100%;
  margin: auto;
  max-height: 600px;
  max-width: var(--SuperHubSearch-max-width);
  min-height: 500px;
  min-width: 850px;
  opacity: 0;
  outline: 0;
  overflow: hidden;
  transform: scale(0.97);
  transform-origin: 50%;
  width: 100%;
  z-index: $z-modal;

  &_withSidebar {
    --SuperHubSearch-max-width: 950px;
  }

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    backdrop-filter: blur(var(--md));
    background: rgba(var(--SuperHubSearch-background), 0.9);
  }

  @media (max-width: 940px) {
    max-width: none;
    min-width: 0;
    width: calc(100vw - 40px);
  }

  @media (max-height: 540px) {
    max-height: calc(100vh - 40px);
    min-height: 0;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    min-height: 0;
  }

  :global(.show-modal) & {
    opacity: 1;
    transform: scale(1);
  }

  // layout
  &-col {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    min-width: 0; // prevent filter col from collapsing when results overflow
    overflow: hidden;
  }
}
