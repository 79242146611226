/* stylelint-disable custom-property-pattern */
@import '@core/styles/vars';

.Header {
  background-color: var(--Header-background);
  box-shadow: 0 1px 0 var(--color-border-default);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  font-size: 14px;
  position: relative;
  z-index: 100;

  div.Header-staging {
    background-color: var(--Header-background);
    box-shadow: inset 0 0 0 1000px rgba(var(--gray20-rgb), 0.2);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-height: 30px;

    a.Header-staging-link {
      color: var(--slate);
      text-decoration: none;
    }

    &-menu {
      opacity: 0.9;
    }

    button.Header-staging-button {
      background: transparent;
      color: var(--Header-button-color, var(--slate));

      &:hover {
        border-color: transparent;
        color: var(--Header-button-color, var(--slate));
      }

      &:active,
      &:focus {
        background: transparent;
        border-color: transparent;
        box-shadow: none;
        color: var(--Header-button-color, var(--slate));
        outline: none;
      }
    }
  }

  &-jumpTo {
    background: var(--Header-jumpTo-background) !important;
    border: 1px solid rgba(black, 0.1);
    color: var(--Header-jumpTo-color) !important;
    font-size: 1em;
    height: $button-sm;
    left: $sm;
    opacity: 0;
    padding: 0 $xs;
    pointer-events: none;
    position: fixed;
    top: $sm;
    z-index: 1;

    // overrwriting the default states
    &:hover {
      background: var(--Header-jumpTo-background) !important;
      color: var(--Header-jumpTo-color) !important;
    }

    &:active,
    &:focus {
      border-color: var(--Header-button-active);
      box-shadow: 0 0 0 3px var(--Header-button-focus);
    }

    &:focus {
      opacity: 1;
      outline: none;
      pointer-events: auto;
    }
  }

  &-top {
    align-items: center;
    border-bottom: var(--Header-border-width) solid var(--Header-border-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50px;

    @media (max-width: $ipad-mini-portrait) {
      overflow-x: auto;
    }

    .Header-search {
      @media (min-width: $ipad-mini-portrait + 1) {
        display: none;
      }
    }

    :global {
      // Styles for our search toggle button in mobile layouts
      .rm-SearchToggle {
        background: none;
        border: 1px solid transparent;
        box-shadow: none;
        color: var(--Header-button-color);

        @media (max-width: $ipad-mini-portrait) {
          width: var(--button-sm);
        }

        &:hover {
          border-color: var(--Header-button-hover);
        }

        &:active,
        &:focus {
          border-color: var(--Header-button-active);
          box-shadow: 0 0 0 3px var(--Header-button-focus);
        }

        &-icon {
          font-size: var(--icon-lg);

          @media (min-width: $ipad-mini-portrait + 1) {
            font-size: var(--icon-md);
          }

          :global(.ThemeContext_dark) & {
            color: var(--Header-button-color);
          }
        }
      }
    }
  }

  &-bottom {
    // prevent fouc when loading
    align-items: center;
    display: flex;
    min-height: 40px;

    // end prevent fouc when loading
    overflow: hidden;
    width: 100%;

    &_withOwlbot {
      @media (max-width: $ipad-mini-portrait) {
        flex: none;
        order: 1;
        width: auto;
      }
    }

    .Header-search {
      @media (max-width: $ipad-mini-portrait) {
        display: none;
      }

      &_owlbot {
        @media (max-width: $ipad-mini-portrait) {
          display: flex;
        }
      }
    }

    &_mobile {
      display: none;

      @media (max-width: $ipad-mini-portrait) {
        display: flex;
      }
    }
  }

  &-right,
  &-left {
    align-items: center;
    display: flex;
    padding-bottom: $xs;
    padding-top: $xs;

    > * + * {
      margin-left: $xs + 1px;
    }
  }

  &-left {
    flex: 1 1 auto;
    -ms-overflow-style: none; // ie
    overflow-x: auto;
    padding-left: $md;
    scrollbar-width: none; // firefox

    @media (max-width: $ipad-mini-portrait) {
      display: none;
    }

    .Header-bottom_withProjects & {
      @media (max-width: $ipad-mini-portrait) {
        display: flex;
        padding-right: 20px;

        :global {
          .Dropdown {
            width: 100%;
          }

          .Header-bottom-link {
            &_mobile {
              @media (max-width: $ipad-mini-portrait) {
                display: none;
              }
            }
          }

          .rm-Header-bottom-link {
            justify-content: space-between;
            width: 100%;
          }
        }
      }
    }

    &_mobile {
      display: none;

      @media (max-width: $ipad-mini-portrait) {
        align-items: center;
        display: flex;
        flex: none;
        margin-right: var(--sm);
      }
    }

    &-nav {
      align-items: center;
      color: var(--Header-button-color);
      display: flex;
      font-weight: var(--font-weight-bold);

      &-icon {
        margin-right: $xs;
      }
    }

    &::-webkit-scrollbar {
      background: transparent;
      height: 0;

      // chrome
      width: 0;
    }
  }

  &-right,
  &-search {
    flex: 0 0 auto;
    padding-right: $md;

    .Header-search {
      padding-right: 0;
    }
  }

  &-right {
    @media (max-width: $ipad-mini-portrait) {
      margin-left: auto;
    }

    &:not(:last-child) {
      @media (max-width: $ipad-mini-portrait) {
        padding-right: 0;
      }
    }

    > :global(.rm-Header-top-link_login) {
      @media (max-width: $ipad-mini-portrait) {
        display: none;
      }
    }

    &_desktop {
      display: flex;
      flex-direction: row;

      & > * {
        margin-left: 6px;
      }

      @media (max-width: $ipad-mini-portrait) {
        display: none;
      }
    }
  }

  &-logo {
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    padding: $xs;

    &:focus {
      border-color: var(--Header-button-active);
      box-shadow: 0 0 0 $xs var(--Header-button-focus);
      outline: 0;
      transition: box-shadow var(--transition-fast) var(--transition-timing);
    }

    &-img {
      height: var(--Header-logo-height);
      width: auto;
    }

    + .Header-link {
      margin-left: $sm;
    }
  }

  &-search {
    align-items: center;
    display: flex;
    margin-left: 3px;
    position: relative;

    &-placeholder {
      flex: 1 1 auto;
      padding-left: $xs;
      text-align: left;
    }

    &_feather {
      box-shadow: -12px 0 6px -6px var(--Header-background);
    }
  }
}

.NavItem {
  &_md {
    height: $lg;
  }

  // for all non-button elements (like when there’s just 1 version)
  &_inactive {
    align-items: center;
    display: flex;
    font-weight: var(--font-weight-bold);
  }

  &_muted {
    color: rgba($slate, 0.75);
    opacity: 0.75;

    &:hover,
    &:active,
    &:focus {
      background: transparent;
    }
  }

  &_dropdown-muted {
    .NavItem-anchor,
    .NavItem-text {
      opacity: 0.5;
    }

    .NavItem-hidden {
      background: rgba($slate, 0.5);
    }
  }

  &-hidden {
    align-items: center;
    background: rgba($slate, 0.75);
    border-radius: 16px;
    color: rgba($white, 0.75);
    display: flex;
    flex-shrink: 0;
    font-size: 12px;
    font-weight: 600;
    height: 16px;
    justify-content: center;
    overflow: hidden;
    width: 16px;

    &_version {
      margin-left: $xs;
    }

    :global(.ThemeContext_dark) & {
      background: $white;
      color: var(--color-primary);
    }

    :global(.ThemeContext_line) & {
      background: rgba($slate, 0.75);
      color: rgba($white, 0.75);
    }

    /* more specific */
    :global(.Menu.Menu) & {
      background: none;
      color: var(--color-text-minimum);
    }
  }

  &-item {
    align-items: center;
    color: var(--Header-button-color, var(--slate));
    display: flex;
    font-weight: var(--font-weight-bold);
    justify-content: space-between;
    padding: $xs $sm;

    &_menu {
      font-size: 14px;
      line-height: 1.4;

      &:hover,
      &:active,
      &:focus {
        background: var(--lightGray, var(--light-gray));
        color: var(--slate);
      }

      &:active {
        background: var(--ivory);
      }

      &-anchor {
        opacity: 0.5;
      }

      .NavItem-hidden {
        background: rgba($slate, 0.75);
        color: rgba($white, 0.75);
      }
    }

    &_inactive,
    &_mobile {
      align-items: center;
      border: var(--border-width) solid transparent;
      font-size: 16px;
      height: $button-md;
      justify-content: flex-start;

      + .NavItem-item {
        margin-top: 2px;
      }
    }

    &_mobile {
      border-radius: var(--border-radius-lg);
      color: var(--Flyout-button-color, var(--color-text-default));
      gap: var(--sm);
      transition: box-shadow var(--transition-fast) var(--transition-timing);

      &:hover {
        border-color: var(--Flyout-button-hover);
        color: var(--Flyout-button-color, var(--color-text-default)); /* again, to override <a /> styles */
      }

      &:active,
      &:focus {
        border-color: var(--Flyout-button-active);
        box-shadow: 0 0 0 3px var(--Flyout-button-focus);
      }

      &:global(.active) {
        background-color: rgba(black, 0.07);

        &:focus {
          background: transparent;
        }
      }

      .NavItem-badge {
        color: var(--Header-button-color);
      }
    }

    &_select {
      background-color: transparent;
      border-color: transparent;
      padding-right: 30px;
      width: 100%;
    }

    &:focus {
      outline: 0;
    }

    &-anchor {
      margin-right: $sm;
    }

    &-flex {
      flex: 1 1 auto;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-codename {
      color: var(--shale);
      font-size: 12px;
      font-weight: var(--font-weight);
      margin-top: $xs !important; // override Flex
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    &-check {
      color: var(--shale);
      flex: 0 0 auto;
      padding-left: $xs;
    }

    &-left {
      align-items: center;
      display: flex;
    }
  }

  &-badge {
    color: var(--color-text-minimum-icon);

   :global(.IconWrapper:first-child) {
      font-size: inherit;
      margin-right: 0;
      width: inherit;
    }
  }
}

.VersionBadge {
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-pill);
  color: $white;
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: var(--font-weight-bold);
  margin-left: $xs;
  padding: 2px $xs;

  &_green {
    background: $green;
  }

  &_gray {
    background: $shale;
  }
}

.ThemeToggle {
  opacity: 0;
  overflow: hidden;
  position: relative;
  width: var(--button-sm);

  &_active {
    opacity: 1;
  }

  /* centering; fml */
  &-wrapper {
    height: var(--button-sm);
  }

  &-Icon {
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    transform: translateY(calc(var(--md) * -1));
    transition:
      opacity var(--transition-fast) linear,
      transform 0.75s var(--transition-timing);

    /* override button defaults */
    &.ThemeToggle-Icon.ThemeToggle-Icon.ThemeToggle-Icon {
      height: var(--icon-md);
      margin: auto;
      opacity: 0;
      width: var(--icon-md);

      @media (max-width: $ipad-mini-portrait) {
        height: var(--icon-lg);
        width: var(--icon-lg);
      }

      &.ThemeToggle-Icon_active {
        opacity: 1;
        transform: none;
      }
    }

    &-star {
      opacity: 0;
      transform: scale(0.5);
      transform-origin: top right;

      .ThemeToggle-Icon_active & {
        animation: star 1s 0.3s forwards;
      }

      @keyframes star {
        0% {
          opacity: 0;
          transform: scale(0.5);
        }

        100% {
          opacity: 0.6;
          transform: scale(0.75);
        }
      }
    }

    &-ray {
      opacity: 0;
      stroke-dasharray: 3;
      stroke-dashoffset: 3;

      .ThemeToggle-Icon_active & {
        animation: ray 1s 0.3s forwards;
      }

      @keyframes ray {
        0% {
          opacity: 0;
          stroke-dashoffset: 3;
        }

        100% {
          opacity: 0.6;
          stroke-dashoffset: 0;
        }
      }
    }

    path {
      fill: none;
      stroke: currentcolor;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
    }
  }
}
